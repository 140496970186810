
import websdk from "easemob-websdk";
import { Options, Vue, setup } from "vue-class-component";
import { formatTime } from "@/utils";
import { MSG_TYPE, MSG_OPT_TYPE } from "@/const";
import { Icon, Tag, Popover, PopoverAction } from "vant";
import { ref } from "vue";

class Props {
  msg!: Record<string, any>;
  timestamp!: number;
  isPinned?: boolean;
}

@Options({
  props: {
    msg: {
      default: () => {
        return {};
      }
    },
    timestamp: 0,
    isPinned: false
  },
  components: {
    Icon,
    Tag,
    Popover
  },
  emits: ["previewImg", "previewCombineMsg", "pinMessage", "unpinMessage"]
})
export default class MessageLeft extends Vue.with(Props) {
  leftMsg = setup(() => {
    let intervalId: number;
    const times = ref(0); // 加载视频重试次数

    const previewImg = (url: string) => {
      this.$emit("previewImg", url);
    };
    const downloadAttach = (url: string) => {
      websdk.utils.download({
        url,
        onFileDownloadComplete: (data: any) => {
          console.log("下载成功", data);
        },
        onFileDownloadError: (error: any) => {
          console.log("下载失败", error);
        }
      });
    };
    const isShowMenu = ref(false);
    const actions: PopoverAction[] = [
      {
        text: this.isPinned ? MSG_OPT_TYPE.unpin : MSG_OPT_TYPE.pin
      }
    ];

    const showMenu = () => {
      clearInterval(intervalId);
      intervalId = setTimeout(() => {
        isShowMenu.value = true;
      }, 300);
    };

    const end = () => {
      clearInterval(intervalId);
    };

    const onSelect = (action: PopoverAction) => {
      if (action.text === MSG_OPT_TYPE.pin) {
        this.$emit("pinMessage");
      } else if (action.text === MSG_OPT_TYPE.unpin) {
        this.$emit("unpinMessage");
      }
    };

    const onVideoError = (id: string, src: string) => {
      let timerId: number = 0;
      timerId && clearInterval(timerId);
      // 加载失败进行重试
      if (times.value < 5) {
        times.value++;
        timerId = setTimeout(() => {
          let video = document.getElementById(id) as HTMLVideoElement;
          video.setAttribute("src", src);
        }, 1000);
      }
    };

    const getCombineMsg = (msg: any) => {
      this.$emit("previewCombineMsg", msg);
    };

    return {
      isShowMenu,
      actions,
      formatTime,
      msgType: MSG_TYPE,
      getCombineMsg,
      previewImg,
      downloadAttach,
      onVideoError,
      onSelect,
      showMenu,
      end
    };
  });
}
