
import { Options, Vue, setup } from "vue-class-component";
import { formatTime } from "@/utils";
import { MSG_TYPE, MSG_OPT_TYPE } from "@/const";
import { ref } from "vue";
import { Icon, Popover, PopoverAction, Tag } from "vant";
import { MessageBody } from "easemob-websdk/src/types/message";
import websdk from "easemob-websdk";
class Props {
  msg!: MessageBody;
  timestamp!: number;
  isPinned!: boolean;
}

@Options({
  props: {
    msg: {
      default: () => {
        return {};
      }
    },
    timestamp: 0,
    isPinned: false
  },
  components: {
    Icon,
    Popover,
    Tag
  },
  emits: [
    "previewImg",
    "recallMessage",
    "modifyMessage",
    "previewCombineMsg",
    "pinMessage"
  ]
})
export default class MessageRight extends Vue.with(Props) {
  rightMsg = setup(() => {
    let intervalId: number;

    const previewImg = (url: string) => {
      this.$emit("previewImg", url);
    };
    const downloadAttach = (url: string) => {
      websdk.utils.download({
        url,
        onFileDownloadComplete: (data: any) => {
          console.log("下载成功", data);
        },
        onFileDownloadError: (error: any) => {
          console.log("下载失败", error);
        }
      });
    };
    const uid = localStorage.getItem("uid");

    const times = ref(0); // 加载视频重试次数

    const isShowMenu = ref(false);

    const actions: PopoverAction[] = [
      {
        text: MSG_OPT_TYPE.pin
      },
      { text: MSG_OPT_TYPE.recall }
    ];
    actions.push({ text: MSG_OPT_TYPE.modify });

    const showMenu = () => {
      clearInterval(intervalId);
      intervalId = setTimeout(() => {
        isShowMenu.value = true;
      }, 300);
    };

    const end = () => {
      clearInterval(intervalId);
    };

    const getCombineMsg = (msg: any) => {
      this.$emit("previewCombineMsg", msg);
    };

    const onSelect = (action: PopoverAction) => {
      if (action.text === MSG_OPT_TYPE.recall) {
        this.$emit("recallMessage");
      } else if (action.text === MSG_OPT_TYPE.modify) {
        this.$emit("modifyMessage");
      } else if (action.text === MSG_OPT_TYPE.pin) {
        this.$emit("pinMessage");
      }
    };

    const onVideoError = (id: string, src: string) => {
      let timerId: number = 0;
      timerId && clearInterval(timerId);
      // 加载失败进行重试
      if (times.value < 5) {
        times.value++;
        timerId = setTimeout(() => {
          let video = document.getElementById(id) as HTMLVideoElement;
          video.setAttribute("src", src);
        }, 1000);
      }
    };

    return {
      actions,
      msgType: MSG_TYPE,
      isShowMenu,
      uid,
      formatTime,
      onSelect,
      previewImg,
      showMenu,
      end,
      getCombineMsg,
      downloadAttach,
      onVideoError
    };
  });
}
